@font-face {
  font-family: "Formula1";
  src: local("Formula1"),
   url("./fonts/Formula1/Formula1-Regular.ttf") format("truetype");
   font-weight: normal;
}

@font-face {
  font-family: "Formula1Bold";
  src: local("Formula1Bold"),
  url("./fonts/Formula1/Formula1-Bold.ttf") format("truetype");
  font-weight: bold;
}

.font-face-f1 {
  font-family: "Formula1";
}

.font-face-f1-bold {
  font-family: "Formula1Bold";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,th{
  font-family: "Formula1";
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
.btn-link { text-decoration: none !important; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color: #000 !important; 
}

.badge-bottom-right {
  position: absolute;
  bottom: 8px;
  right: 6px;
}

.fastest{
  color: #AB3CDE !important;
}
.invalidated{
  color: red !important;
}
.faster{
  color: #00cc00 !important;
}

.logs{
  max-height: 1000px;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
}

.logline{
  font-size: 13px;
}

.smallFont{
  font-size: 13px;
}

.racetab {
  border-spacing: 0px !important;
}

.racetab td, .racetab th {
  vertical-align: middle !important;
  padding: 0px !important;
}

.racetab th {
  text-align: center !important;
}

.positionCell {
  width: 45px;
  white-space: nowrap !important;
}

.timing {
  width: 100%;
  height: 4px;
  display: block;
}
.timing-fastest {background-color: #ff00ff !important;}
.timing-faster {background-color: #43D845 !important;}
.timing-invalidated {background-color: red !important;}
.timing-normal {background-color: darkgrey !important;}

.cell-small {
  text-align: center !important;
  width: 60px;
}

.centerTxt {
  text-align: center;
}

.left-align {
  text-align: left !important;
}

.right-align {
  text-align: right !important;
}

.team-cell {
  background-color:grey;
  height: 26px;
  width: 4px;
  float: left;
  margin-right: 4px;
}

.cell-driver {
  white-space: nowrap;
}

.lp-bestlap{
  text-align: center !important;
  width: 190px;
  padding: 0px;
  margin: 0px;
}
.lp-delta{
  text-align: center !important;
  width: 80px;
}
.lp-sector {
  width: 33%;
  font-size: 12px;
  padding-left: 3px;
  padding-right: 3px;
}

.flag-icon {
  height: 60px;
  vertical-align: middle;
}

.flag-icon-small {
  height: 25px;
  vertical-align: middle;
}

#weather {
  width: 100%;
  overflow: auto;
}

.weather-icon {
  height: 40px;
  vertical-align: middle;
}

.tyre {
  background-color: black;
  border-radius: 80%;
}

.inline-block{
  white-space: nowrap;
}

.team-ferrari{ background-color: #db3832;}
.team-alfaromeo { background-color: #a12f3b;}
.team-haas { background-color: #b7babd;}
.team-mclaren { background-color: #d87e37;}
.team-mercedes { background-color: #86d1bf;}
.team-rp { background-color: #46806e;}
.team-redbull { background-color: #305abe;}
.team-renault { background-color: #468ec8;}
.team-alphatauri { background-color: #587b98}
.team-williams { background-color: #62bbd9;}

.reset-zoom {
	position: absolute;
	top: 0px;
	right: 20px;
}
